import React from 'react';
import Navbar from '../../../components/trivia/NavbarOld';
import Testimonials from '../../../components/trivia/TestimonialsOld';
import Footer from '../../../components/trivia/Footer';
import '../slack/styles.scss';
import './styles.scss';
import { Helmet } from 'react-helmet';
import WorkSpace from '../../../components/trivia/WorkSpace';
import CaseStudies from '../../../components/trivia/CaseStudies';

const users = [
    'Quora-logo.svg',
    'sugoilabs-logo.svg',
    'ea1-logo.svg',
    'consenso-logo.svg',
    'skillenza-logo.svg',
    's-logo.svg',
    'Science-logo.svg',
];

class Customers extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Success Stories | Trivia</title>
                    <meta
                        name='description'
                        content='Have a look at our success stories & see why everyone loves Trivia! With over 75K+ players across 3K+ companies, Trivia is engaging employees in 40+ countries.'
                    />
                    <meta property='og:site_name' content='Springworks' />
                    <meta property='og:title' content='Success Stories | Trivia' />
                    <meta
                        property='og:description'
                        content='Have a look at our success stories & see why everyone loves Trivia! With over 75K+ players across 3K+ companies, Trivia is engaging employees in 40+ countries.'
                    />
                    <meta property='og:url' content='https://www.springworks.in/trivia/customers/' />
                    <meta property='og:type' content='website' />
                    <meta
                        property='og:image'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaCustomers.jpg'
                    />
                    <meta
                        property='og:image:secure_url'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaCustomers.jpg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta property='twitter:title' content='Success Stories | Trivia' />
                    <meta
                        property='twitter:description'
                        content='Have a look at our success stories & see why everyone loves Trivia! With over 75K+ players across 3K+ companies, Trivia is engaging employees in 40+ countries.'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta
                        property='twitter:image'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaCustomers.jpg'
                    />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link rel='canonical' href='https://www.springworks.in/trivia/customers/' />
                </Helmet>
                <div className='pageWrap customerWrap'>
                    <Navbar />

                    <div className='slackHeader'>
                        <h1>5,000+ organizations trust Trivia for better team bondings</h1>
                        <p>A suite of fun-filled games. Purpose-built for stronger, happier, and healthier teams.</p>
                    </div>
                    <div className='clients-logos'>
                        {users.map((user) => (
                            <span>
                                <img
                                    src={`https://sw-assets.gumlet.io/trivia/customers/${user}?blur=30`}
                                    data-src={`https://sw-assets.gumlet.io/trivia/customers/${user}`}
                                    alt={user}
                                />
                            </span>
                        ))}
                    </div>

                    <CaseStudies />
                    <Testimonials />
                    <WorkSpace />
                    <Footer />
                </div>
            </>
        );
    }
}
export default Customers;
